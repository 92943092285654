<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <input type="text" v-model="text" />
    <br />
    text: {{ text }}
    <br />
    <button type="button" @click="request">send</button>
    <br />
    result: {{ result }}
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  created() {
    console.log("domain:", document.domain);
  },
  data: () => ({
    text: "",
    result: "",
  }),
  methods: {
    request() {
      let url = "https://www.scrapoo-sian.ml/hello";
      // let url = "http://0.0.0.0:8000/hello";
      let conf = {
        method: "GET",
        url: url,
        params: { query: this.text },
      };
      axios(conf).then((res) => {
        this.result = res.data;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
